
import './App.css';
import logo from './images/logo.jpeg';
import event from './images/event.jpeg';
import strings from './strings/es.json'
import iconIG from './images/instagram-fill-svgrepo-com.svg';

function App() {
  return (
    <>
      <div className="container">
        <div className='logo-container'>
          <img className='logo' src={logo} alt='logo'></img>
        </div>
        <div className='texts-container'>
          <div className='title-container'>
            <p>25 Marzo 2024</p>
            <p>Terrazas VIP</p>
            <p>10:30 PM - 4:00 AM</p>
          </div>
        </div>
        <div>
          <div className='event-img-container'>
            <img className='event' src={event} alt='event'></img>
          </div>
          <div className='button-reserve-container'>
            <button className="button-85" role="button">{strings.btn_1}</button>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className='footer-social-media'>
          <span>© COLORS BY LAPENTA</span>
        </div>
      </div>
    </>
  );
}

export default App;
